"use strict";

// Class definition
var KTUserForm = function () {
    // Elements
    var input_role;

    // Handle form
    var handleForm = function (input) {
        if (input.hasAttribute("data-show-branch")) $("#branch_field").show();
        else $("#branch_field").hide();

        if (input.hasAttribute("data-show-category")) $("#category_field").show();
        else $("#category_field").hide();

    };

    // Public functions
    return {
        // Initialization
        init: function () {
            input_role = document.querySelector("input[name='user[roles]']");
            document.querySelectorAll("input[name='user[roles]']").forEach((input) => {
                input.addEventListener('change', function (event) {
                    handleForm(event.target);
                });
            });
        }
    };
}();

// On document ready
KTUtil.onDOMContentLoaded(function () {
    KTUserForm.init();
});


// Webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = KTUserForm;
}
