// Keenthemes' plugins
window.KTUtil = require('@/assets/src/js/components/util.js');
//window.KTBlockUI = require('@/assets/src/js/components/blockui.js');
//window.KTCookie = require('@/assets/src/js/components/cookie.js');
window.KTDialer = require('@/assets/src/js/components/dialer.js');
//window.KTDrawer = require('@/assets/src/js/components/drawer.js');
window.KTEventHandler = require('@/assets/src/js/components/event-handler.js');
//window.KTFeedback = require('@/assets/src/js/components/feedback.js');
//window.KTImageInput = require('@/assets/src/js/components/image-input.js');
window.KTMenu = require('@/assets/src/js/components/menu.js');
//window.KTPasswordMeter = require('@/assets/src/js/components/password-meter.js');
//window.KTScroll = require('@/assets/src/js/components/scroll.js');
//window.KTScrolltop = require('@/assets/src/js/components/scrolltop.js');
window.KTSearch = require('@/assets/src/js/components/search.js');
//window.KTStepper = require('@/assets/src/js/components/stepper.js');
window.KTSticky = require('@/assets/src/js/components/sticky.js');
//window.KTSwapper = require('@/assets/src/js/components/swapper.js');
window.KTToggle = require('@/assets/src/js/components/toggle.js');
window.KTUserForm = require('@/assets/src/js/custom/user-form.js');

// Layout base js
window.KTApp = require('@/assets/src/js/layout/app.js');
window.KTLayoutAside = require('@/assets/src/js/layout/aside.js');
window.KTLayoutSearch = require('@/assets/src/js/layout/search.js');
window.KTLayoutToolbar = require('@/assets/src/js/layout/toolbar.js');

